<template>
  <v-dialog v-model="visible" width="300">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" color="red" depressed icon small>
        <v-icon small>mdi-delete</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-capitalize">
        Delete {{ elementType }}
      </v-card-title>

      <v-card-text class="">
        <div class="black--text font-weight-medium text-body-1 mt-3">
          {{ bodyContent }}
        </div>
        <div class="mt-2 font-weight-medium secondary--text text-body-2">
          {{ firstLine }}
        </div>
        <div class="font-weight-medium secondary--text text-body-2">
          {{ secondLine }}
        </div>
        <div class="font-weight-medium secondary--text text-body-2">
          {{ town }}
        </div>
        <div class="font-weight-medium secondary--text text-body-2">
          {{ county }}
        </div>
        <div class="font-weight-medium secondary--text text-body-2">
          {{ postCode }}
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn text @click="visible = false"> Close </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="blue" text @click="confirmDelete"> Delete </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    elementType: {
      type: String,
      default: "",
    },
    bodyContent: {
      type: String,
      default: "",
    },
    firstLine: {
      type: String,
      default: "",
    },
    secondLine: {
      type: String,
      default: "",
    },
    town: {
      type: String,
      default: "",
    },
    county: {
      type: String,
      default: "",
    },
    postCode: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    visible: false,
  }),
  methods: {
    confirmDelete() {
      this.$emit("delete-confirmed")
      this.visible = false
    },
  },
}
</script>

<style></style>
